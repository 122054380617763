<section
    class="group flex justify-between"
    [ngClass]="{
        'text-text-primary': selected,
        'text-text-mid-contrast hover:text-text focus:text-text': !selected,
    }"
>
    <button
        class="rounded-4 flex w-full items-center overflow-hidden pl-8 text-left"
        type="button"
        (click)="communitySelected.emit()"
    >
        <interacta-icon
            class="mr-8 size-24 shrink-0"
            [icon]="'community'"
            [matTooltip]="community.name | currentLanguage"
            [matTooltipDisabled]="expanded"
        />
        <span
            class="typo-xs-bold line-clamp-2 grow break-words transition-opacity duration-500"
            [ngClass]="{ 'opacity-0': !expanded, 'opacity-100': expanded }"
            >{{ community.name }}</span
        >
    </button>
    @if (pinVisible && expanded) {
        <button
            #pinButton
            type="button"
            [attr.aria-label]="
                (pinned ? 'SIDEBAR.UNPIN_COMMUNITY' : 'SIDEBAR.PIN_COMMUNITY')
                    | translate
            "
            [attr.aria-pressed]="pinned"
            [ngClass]="{
                'opacity-0 focus:opacity-100 group-hover:opacity-100': !pinned,
                'opacity-100': pinned,
            }"
            (click)="pinClicked()"
        >
            <interacta-icon
                class="block size-20 transition-transform duration-300"
                [icon]="'pin'"
                [ngClass]="{
                    'rotate-45 hover:rotate-0 ': !pinned,
                    'rotate-0 hover:rotate-45 ': pinned,
                }"
            />
        </button>
    }
</section>
