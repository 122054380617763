import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Theme } from '@interacta-shared/data-access-theme';

@Component({
    selector: 'interacta-sidebar-logo',
    templateUrl: './sidebar-logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLogoComponent {
    @Input({ required: true }) currentTheme!: Theme;
    @Input() enableChristmasEasterEgg = false;

    christmasDialogOpen = false;
}
