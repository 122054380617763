<a
    class="typo-xs-bold flex w-full items-center gap-x-12"
    [ngClass]="{
        grow: !expanded,
        'text-text-mid-contrast hover:text-text': !selected,
    }"
    [routerLink]="[section.path]"
>
    <interacta-icon
        class="mx-6 my-4 size-16 shrink-0 transition-all duration-300"
        [icon]="'square-fill'"
        [matTooltip]="section.label | translate"
        [matTooltipDisabled]="expanded"
        [ngClass]="{
            'text-text-primary': selected,
        }"
    />

    <span
        class="grow text-left transition-all duration-300"
        [ngClass]="{
            'text-text': selected,
            'opacity-0': !expanded,
            'opacity-100': expanded,
        }"
    >
        {{ section.label | translate }}</span
    >

    <span
        *ngIf="newSection() && expanded"
        class="bg-surface-primary-low-contrast ml-8 mt-2 rounded-3xl px-8 py-2"
    >
        {{ 'SIDEBAR.NEW_SECTION_BADGE' | translate }}
    </span>
</a>
