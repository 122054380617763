<img
    *ngIf="!enableChristmasEasterEgg"
    alt="logo"
    class="my-20 ml-10 w-44 shrink-0"
    [src]="currentTheme.mode | appLogo"
/>
<button
    *ngIf="enableChristmasEasterEgg"
    class="my-20 ml-20 w-44 shrink-0"
    type="button"
    (click)="christmasDialogOpen = true"
>
    <img
        alt="christmas gift image"
        [src]="
            currentTheme.mode === 'dark'
                ? './assets/images-2.0/gift_dark.svg'
                : './assets/images-2.0/gift.svg'
        "
    />
</button>
<interacta-dialog
    *ngIf="enableChristmasEasterEgg && christmasDialogOpen"
    [isOpen]="christmasDialogOpen"
    [size]="'regular'"
    (close)="christmasDialogOpen = false"
    (closing)="christmasDialogOpen = false"
>
    <div title></div>
    <video
        autoplay
        class="h-screen w-full overflow-hidden pb-32"
        content
        muted
        [currentTime]="0"
        [src]="
            'https://storage.googleapis.com/interacta-registry-main-storage-prod/shared/assets/easter-eggs/happy_holidays.mp4'
        "
        (click)="$event.stopImmediatePropagation()"
        (ended)="christmasDialogOpen = false"
        (error)="christmasDialogOpen = false"
    >
        <p>{{ 'HAPPY_HOLIDAYS' | translate }}</p>
    </video>
</interacta-dialog>
