import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject,
    signal,
} from '@angular/core';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import {
    SidebarSectionAdmin,
    SidebarSectionAdminId,
} from '@modules/sidebar/models/sidebar.model';

@Component({
    selector: 'interacta-sidebar-admin-section',
    templateUrl: './sidebar-admin-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarAdminSectionComponent implements OnInit, OnChanges {
    @Input({ required: true }) section!: SidebarSectionAdmin;
    @Input() expanded = true;
    @Input() selected = false;
    @Input() selectedSectionId: SidebarSectionAdminId | null = null;

    newSection = signal<boolean>(false);
    private discoveryService = inject(DiscoveryService);

    ngOnInit(): void {
        const newSectionIds = this.discoveryService.getNewAdminSectionIds();
        this.newSection.set(newSectionIds.includes(this.section.id) ?? false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected) {
            this.selected && this.newSection.set(false);
        }
    }
}
