<div class="mx-12 flex flex-col gap-y-8 overflow-x-hidden">
    @for (group of visibleSectionAdminGroups(); track $index) {
        <interacta-expansion-panel
            [collapsed]="group.collapsed"
            [iconColor]="'gray-700'"
            [lineThickness]="'none'"
            (toggleCollapsed)="collapseGroup($index)"
        >
            <div
                *ngIf="{
                    groupItemSelected:
                        {
                            sections: group.sections,
                            sectionSelected: sectionSelected() ?? null,
                        } | apply: innerSectionSelected,
                } as $$"
                class="flex items-center"
                title
                [ngClass]="{
                    'text-text-mid-contrast hover:text-text':
                        !$$.groupItemSelected,
                }"
            >
                <interacta-icon
                    class="mx-10 size-24"
                    [icon]="group.icon"
                    [matTooltip]="group.label | translate"
                    [matTooltipDisabled]="expanded"
                    [ngClass]="{
                        'text-text-primary': $$.groupItemSelected,
                    }"
                />
                <span
                    class="typo-xs-bold-uppercase"
                    [ngClass]="{
                        'text-text': $$.groupItemSelected,
                        'opacity-0': !expanded,
                        'opacity-100': expanded,
                    }"
                >
                    {{ group.label | translate }}
                </span>
            </div>

            <article class="flex flex-col gap-y-8" content>
                @for (item of group.sections; track item.id) {
                    <interacta-sidebar-admin-section
                        class="grow pl-8"
                        [expanded]="expanded"
                        [section]="item"
                        [selected]="item.id === sectionSelected()"
                        [selectedSectionId]="sectionSelected() ?? null"
                    />
                }

                @if (!group.collapsed) {
                    <interacta-separator
                        [ngClass]="{ 'block w-40': !expanded }"
                        [thickness]="'soft'"
                    />
                }
            </article>
        </interacta-expansion-panel>
    }
</div>
